//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['article']
}
