//
//
//
//
//
//
//
//
//
//
//
//


import PseudoDataItem from '../components/views/PseudoDataItem.vue'
import DataItem from '../components/views/DataItem.vue'

export default {
    props: ['except'],
    head() {
        return {
            titleTemplate: '%s',
            meta: [
                { hid: 'og:title', name: 'og:title', template: chunk => `${chunk}`},
                { hid: 'twitter:title', name: 'twitter:title', template: chunk => `${chunk}` },
            ],
        }
    },
    data() {
        return {
            page: 1,
            news : [],
            blocks: [],
            pages: []
        }
    },
    components: {
        PseudoDataItem,
        DataItem
    },
    async fetch({store}, except) {
        if (store.getters['categories'].length === 0) {
            await store.dispatch('getCategories')
        }
    },
    async asyncData({ store, except }) {

        store.commit('news/clearPages')

        const news = await store.dispatch('news/getList', {
            page: 1,
            main: true,
            except: except,
            status: 'visible',
            sort: 'published_at|desc',
        })

        const pages = store.getters['news/pages']
        const blocks = store.getters['news/blocks']
        
        return { news, blocks, pages }
    },
    computed: {
        categories() {
            return this.$store.getters['categories']
        },
        preview() {
            return this.news.length
        }
    }, 
    mounted() {
        this.$store.commit('news/setCategory', {})
    },
    updated() {
        // STATUS.COMPLETE = 2 of InfiniteLoading component 
        if (this.$refs.infiniteLoading.status == 2 && this.page == 1) {
            this.$refs.infiniteLoading.stateChanger.reset()
        }
    },
    methods: {
        async infiniteScroll($state) {
            this.page++;

            const data = await this.$store.dispatch('news/getList', {
                    page: this.page,
                    main: true,
                    except: this.$props.except,
                    status: 'visible',
                    sort: 'published_at|desc',
                })

            this.news.push(...data);
            this.pages = this.$store.getters['news/pages']
            this.blocks = this.$store.getters['news/blocks']

            if (this.page >= this.$store.getters['news/lastPage']) {
                $state.complete();
            } else {
                $state.loaded();
            }
        }
    }
}
