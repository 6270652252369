//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      image: '/images/placeholders/placeholder.jpg',
      storage: '/storage/news/',
      categories: [{
        id: 28,
        name: "Для ЗСУ",
        image: "https://donbas24.news/storage/news/18vae16jxac0ui0p.jpeg",
        uri: "volonterski-sili-ukrayini-dlya-zsu",
      },{
        id: 29,
        name: "Для цивільних",
        image: "https://donbas24.news/storage/news/atgbcx1wgyocxmhj.jpeg",
        uri: "volonterski-sili-ukrayini-dlya-civilnix",
      },{
        id: 30,
        name: "Для лікування",
        image: "https://donbas24.news/storage/news/bcomepv3ubgrmw40.jpeg",
        uri: "volonterski-sili-ukrayini-dlya-likuvannya",
      }],
    }
  }
}
