//
//
//
//
//
//
//
//
//
//
//

import Content from '../rows/Content'
import Inset from '../rows/Inset'

export default {
    components: {
        Content,
        Inset
    },
    props: ['news', 'blocks', 'lastIndex', 'firstIndex', 'pages'],
  }
