//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Block from '../blocks/Block'
import BlockFeed from '../blocks/BlockFeed'
import LinkSlider from '../sliders/LinkSlider'

export default {
  components: {
    Block,
    BlockFeed,
    LinkSlider
  },
  props: ['news', 'blocks']
}
